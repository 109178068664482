.interaction {
  width: 150%;
  position: absolute;
  left: 50%;
  bottom: 0;
  transform: translate3d(-50%, 75%, 0);
  -webkit-transform: translate3d(-50%, 75%, 0);
  background: radial-gradient(
    50% 50% at 50% 50%,
    #89b7c6 0%,
    rgba(255, 255, 255, 0.5) 78.65%,
    rgba(255, 255, 255, 0) 100%
  );
  filter: blur(20px);
  border-radius: 50%;
  transition: width 2s;
  will-change:
    transform,
    -webkit-transform;
  perspective: 1000;
  -webkit-perspective: 1000;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
}

.interaction:after {
  content: '';
  display: block;
  padding-bottom: 60%;
}

.interaction.active {
  width: 900%;
}
